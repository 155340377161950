import { Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";

@Module
export default class OrdersModule extends VuexModule {
  @Action
  [Actions.GET_ORDERS](accountId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/v1/accounts/${accountId}/orders`, "")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }
}
