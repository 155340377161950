import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Account {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}

export interface AccountInfo {
  errors: Array<string>;
  account: Account;
  isAuthenticated: boolean;
}

@Module
export default class AccountModule extends VuexModule implements AccountInfo {
  errors = [];
  account = {} as Account;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): Account {
    return this.account;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_ACCOUNT](account) {
    this.account = account;
  }

  @Action
  [Actions.GET_ACCOUNT_DETAILS](accountId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/v1/accounts/" + accountId, "")
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SUBACCOUNTSFTX](input: { apikey: string; secret: string }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.query(`/v1/ftx/subaccounts`, {
        params: { apikey: input.apikey, secret: input.secret },
      })
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_COPYACCOUNT](accountid) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/v1/accounts/${accountid}/subaccounts`, "")
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ACCOUNT]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/v1/accounts", "")
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_ACCOUNT](accountId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("/v1/accounts/" + accountId)
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  // or set it to inactive. backend will switch
  @Action
  [Actions.SET_ACCOUNT_ACTIVE](account) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        "/v1/accounts/" + account.id + "/active" + "/" + account.active,
        {
          data: account,
        }
      )
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  // or set it to inactive. backend will switch
  @Action
  [Actions.SET_COPYACCOUNT_ACTIVE](subaccount) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("/v1/accounts/subaccounts/" + subaccount.id + "/active", {
        data: subaccount,
      })
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  // get the copy account settings data
  @Action
  [Actions.GET_COPYACCOUNTSETTINGS](subaccountId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/v1/subaccounts/" + subaccountId + "/settings")
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COPYACCOUNTSETTINGS](body) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        "/v1/subaccounts/" + body.copyAccountId + "/settings",
        body
      )
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_COPY_ACCOUNT](Ids) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(
        "/v1/accounts/" + Ids.mainaccountId + "/subaccounts/" + Ids.subaccountId
      )
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_COPY_ACCOUNT](accountObj) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`/v1/accounts/${accountObj.accountId}/subaccounts`, {
        data: accountObj,
      })
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_ACCOUNT](accountObj) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/v1/accounts", { data: accountObj })
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
