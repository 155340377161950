import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Profile {
  id: number;
  username: string;
  nickname: string;
  contactPhone: string;
  language: string;
  timeZone: string;
}

export interface ProfileInfo {
  errors: Array<string>;
}

@Module
export default class ProfileModule extends VuexModule implements ProfileInfo {
  errors = [];
  profile = {} as ProfileInfo;

  @Action
  [Actions.UPDATE_PROFILE](profile) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("/v1/profile", profile)
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PROFILE]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/v1/profile", "")
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
