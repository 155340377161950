enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_ACCOUNT = "getAccount",
  GET_PROFILE = "getProfile",
  CREATE_ACCOUNT = "createAccount",
  CREATE_COPY_ACCOUNT = "createCopyAccount",
  DELETE_ACCOUNT = "deleteAccount",
  DELETE_COPY_ACCOUNT = "deleteCopyAccount",
  GET_ACCOUNT_DETAILS = "getAccountDetails",
  GET_COPYACCOUNT = "getCopyAccounts",
  SET_COPYACCOUNT_ACTIVE = "setCopyAccountInactive",
  GET_COPYACCOUNTSETTINGS = "getCopyAccountSettings",
  UPDATE_COPYACCOUNTSETTINGS = "updateCopyAccountSettings",
  SET_ACCOUNT_ACTIVE = "setAccountInactive",
  GET_ORDERS = "getOrders",
  UPDATE_PROFILE = "updateProfile",
  GET_SUBACCOUNTSFTX = "getSubAccountsFTX",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_ACCOUNT = "setAccount",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
}

export { Actions, Mutations };
