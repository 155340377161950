import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import ProfileModule from "@/store/modules/Profile";
import Accounts from "@/store/modules/Accounts";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import OrdersModule from "@/store/modules/Orders";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    Accounts,
    OrdersModule,
    ProfileModule,
  },
});

export default store;
